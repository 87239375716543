import * as Sentry from '@sentry/gatsby';
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: process.env.GATSBY_SENTRY_DSN,
  integrations: [
    new BrowserTracing()
  ],
  tracesSampleRate: 0.2,
});
